/* CardBillingHistory.css react-lib */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px) {
    .rps-billing-history-filterbox .rps-form-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0.4rem;
    }
}